<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'所有机器人设备'" :total="query.total" />

            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>

        <div class="layoutTabaleBorder">
            <el-table :data="query.tableData" style="width: 100%">
                <el-table-column
                    prop="equipment_id"
                    label="设备ID"
                    width="400"
                >
                </el-table-column>

                <el-table-column prop="manufacturer" label="生产商">
                </el-table-column>

                <el-table-column prop="product.product_name" label="产品所属">
                </el-table-column>

                <el-table-column prop="agv_status" label="agv状态">
                </el-table-column>

                <el-table-column
                    label="更多"
                    align="center"
                    fixed="right"
                    width="240"
                >
                    <template slot-scope="scope">
                        <el-button @click="toAgvInfo(scope.row.equipment_id)"
                            >详情</el-button
                        >
                        <!-- <el-button @click="toAgvCheck(scope.row.equipment_id)"
                            >检查</el-button
                        > -->
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="layoutBorder">
            <Pagination
                :total="query.total"
                :page.sync="query.currentPage"
                @pagination="getList()"
                :limit.sync="query.pageSize"
            />
        </div>
    </div>
</template>

<script>
import { agv_get } from '@/api/manage/operation/agv/agv.js';
import { manufacturer_get } from '@/api/manage/operation/product/manufacturer.js';
import { product_get } from '@/api/manage/operation/product/product.js';
import Pagination from '@/components/Pagination.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

export default {
    name: 'AGV',
    data() {
        return {
            formdata: {
                keyword: '',
                agv_status: 0,
                manufacturer_id: '',
                product_id: '',
                querypage: 1,
                pagesize: 10
            },
            // 接口数据
            query: {
                tableData: [],
                currentPage: 1,
                total: 0,
                pageCount: 0,
                pageSize: 10
            },
            manufacturerData: [],
            productData: [],
            formConfig: [
                {
                    label: '关键字',
                    prop: 'keyword',
                    component: 'el-input',
                    placeholder: '请输入关键词'
                },
                {
                    label: 'AGV状态',
                    prop: 'agv_status',
                    component: 'el-select',
                    placeholder: '请选择状态',
                    clearable: true,
                    options: [
                        { label: '装配中', value: 1 },
                        { label: '待测试', value: 2 },
                        { label: '待出厂', value: 3 },
                        { label: '已回厂', value: 4 },
                        { label: '待测试"', value: 5 },
                        { label: '待出厂', value: 6 },
                        { label: '待测试"', value: 7 }
                    ]
                },
                {
                    label: '生产商',
                    prop: 'manufacturer_id',
                    component: 'el-select',
                    placeholder: '请选择生产商',
                    clearable: true,
                    options: []
                }
            ],
            formModel: {},
            buttons: [{ label: '查询', type: 'primary', action: 'search' }]
        };
    },
    components: {
        Pagination,
        CommonHeaders
    },
    created() {
        this.getList(); // 定义该函数在页面一加载时就执行
    },
    methods: {
        // 获取接口数据的方法
        getList() {
            agv_get({
                querypage: this.query.currentPage,
                pagesize: this.query.pageSize,
                keyword: this.formdata.keyword,
                agv_status: this.formdata.agv_status
            }).then(res => {
                if (res.code == 200) {
                    this.query.total = res.data.total;
                    this.query.currentPage = res.data.currentPage;
                    this.query.pageCount = res.data.pageCount;
                    this.query.tableData = res.data.result;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        duration: 5 * 1000
                    });
                }
            }),
                manufacturer_get({}).then(res => {
                    if (res.code == 200) {
                        // 生产商选择器
                        this.formConfig[2].options = res.data.map(item => ({
                            label: item.production_name,
                            value: item.id
                        }));
                    }
                }),
                product_get({
                    querypage: this.query.currentPage,
                    pagesize: this.query.pageSize,
                    keyword: this.formdata.keyword,
                    is_delete: false
                }).then(res => {
                    console.log(res);
                    if (res.code == 200) {
                        this.productData = res.data.result;
                    }
                });
        },
        handleButtonClick(button, formData) {
            if (button.action === 'search') {
                this.handleFormSubmit(formData);
            } else if (button.action === 'create') {
                this.addFormButton();
            }
        },
        handleFormSubmit(formData) {
            this.formdata.keyword = formData.keyword;
            this.formdata.agv_status = formData.agv_status;
            this.formdata.manufacturer_id = formData.manufacturer_id;

            agv_get(this.formdata).then(res => {
                this.query.total = res.data.total;
                this.query.tableData = res.data.result;
            });
        },
        toAgvInfo(ids) {
            this.$router.push({
                name: 'agv_Info',
                query: {
                    ids: ids
                }
            });
        },
        toAgvCheck(ids) {
            this.$router.push({
                name: 'agv_Check',
                query: {
                    ids: ids
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
