import request from '@/utils/request'


// agv管理接口 -----------------------------------------------------------------


// 一级-agv-查询
export function agv_get(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/query',
        method: 'post',
        data: data
    })
}


// 一级-agv-创建
export function agv_create(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/create',
        method: 'post',
        data: data
    })
}
// 二级-agv-agv状态列表-查询
export function agv_status_list(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/create/option/agv_status_list',
        method: 'post',
        data: data
    })
}
// 二级-agv-指定agv-查询
export function agv_info_get(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/get',
        method: 'post',
        data: data
    })
}

// 二级-agv-更新状态（关联）-更新
export function agv_status_link_garage_update(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/garage_relevance/create',
        method: 'post',
        data: data
    })
}


// 二级-agv-更新状态（取消关联）-更新
export function agv_status_unlink_garage_update(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/garage_relevance/delete',
        method: 'post',
        data: data
    })
}


// 二级-agv-更新运行状态-更新
export function agv_status_work_update(data) {
    return request({
        url: '/platform_api/agv_manage/add',
        method: 'post',
        data: data
    })
}


// 二级-agv运营记录-获取
export function agv_record_get(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/operating_record/query',
        method: 'post',
        data: data
    })
}



// 证书密钥管理-------------------------------------------------------------------------


// 二级-agv证书-查询
export function agv_licence_get(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/licence/query',
        method: 'post',
        data: data
    })
}


// 二级-agv证书-创建
export function agv_licence_create(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/licence/create',
        method: 'post',
        data: data
    })
}


// 二级-agv证书-更新
export function agv_licence_update(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/licence/update',
        method: 'post',
        data: data
    })
}